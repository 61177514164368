import "@/scss/theme.scss";
import { Layout } from "@/ui/Layout";
import { ParallaxProvider } from "react-scroll-parallax";
import SourceFlowTracker from "@sourceflow-uk/sourceflow-tracker";

export default function App({ Component, pageProps }) {
  switch (pageProps.layout) {
    case "minimal":
      return (
        <ParallaxProvider>
          <Component {...pageProps} />
        </ParallaxProvider>
      );

    default:
    case "default":
      return (
        <ParallaxProvider>
          <Layout id={pageProps.prefix}>
          <SourceFlowTracker />
            <Component {...pageProps} />
          </Layout>
        </ParallaxProvider>
      );
  }
}
